import { connection } from "./jsstore_con";
import { Profile } from "../helpers/player";

export class ProfileService {

    tableName: string;

    constructor() {

        this.tableName = "Profiles";
    }

    getProfiles() {
        return connection.select<Profile>({
            from: this.tableName,
        })
    }

    addProfile(profile: Profile) {
        return connection.insert<Profile>({
            into: this.tableName,
            values: [profile],
            return: true
        })
    }

    getProfileById(id: number) {
        return connection.select<Profile>({
            from: this.tableName,
            where: {
                id: id
            }
        })
    }

    removeProfile(key: number) {
        return connection.remove({
            from: this.tableName,
            where: {
                key: key
            }
        })
    }

    updateProfileById(profile: Profile) {
        return connection.update({
            in: this.tableName,
            set: {
                name: profile.name,
                id: profile.id,
                platform: profile.platform,
            },
            where: {
                id: profile.id
            }
        })
    }

    updateLastOnline(key: number, date: Date) {
        return connection.update({
            in: this.tableName,
            set: {
                lastOnline: date,
            },
            where: {
                key: key
            }
        })
    }

    clearProfiles() {
        return connection.clear(this.tableName);
    }
}