















































import { Player, Profile } from "../helpers/player";
import { ProfileService } from "../service/profile_service";
import moment from "moment";
import Vue from 'vue';
const profiles: Profile[] = [];

export default Vue.extend({
  data() {
    return {
      deleted: false,
      dialog: false,
      notifications: false,
      sound: true,
      widgets: false,
      profiles: profiles,
      service: new ProfileService(),
    };
  },
  watch: {
    dialog(value) {
      if (value) this.getProfiles();
    },
  },
  methods: {
    moment: moment,
    async getProfiles() {
      profiles.length = 0;
      profiles.push(...(await this.service.getProfiles()));
      profiles.sort((a, b) => a.lastOnline > b.lastOnline ? -1 : 1);
    },
    close() {
      if (this.deleted) location.reload();
      this.dialog = false;
    },
    remove(profile: Profile) {
      const index = profiles.map((x) => x.id).indexOf(profile.id);
      if (index !== -1 && profile.key != null) {
        this.$delete(profiles, index);
        this.service.removeProfile(profile.key);
      }
      this.deleted = true;
    },
  },
});
