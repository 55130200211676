







import { Component, Vue } from 'vue-property-decorator';
import DataTable from '@/components/DataTable.vue'; // @ is an alias to /src

@Component({
  components: {
    DataTable,
  },
})
export default class Home extends Vue {}
