





















import Vue from 'vue';
import DataTable from './components/DataTable.vue';
import { initJsStore } from "./service/idb_service";

export default Vue.extend({
  name: 'App',

  components: {
    DataTable,
  },
  async beforeCreate() {
    try {
      const isDbCreated = await initJsStore();
    } catch (ex) {
      console.error(ex);
      alert(ex.message);
    }
  },
  data: () => ({
    //
  }),
});
