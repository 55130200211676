import { BungieMembershipType } from "bungie-api-ts/destiny2";

export class Match {
    public id: string;
    public date: Date;
    public activity: string;
    public mode: string;
    public duration: string;
    public victory: boolean;
    public platform: BungieMembershipType;

    constructor(id: string, date: Date, activity: string, mode: string, duration: string, victory: boolean, platform: BungieMembershipType) {
        this.id = id;
        this.date = date;
        this.activity = activity;
        this.mode = mode;
        this.duration = duration;
        this.victory = victory;
        this.platform = platform;
    }
}