import { BungieMembershipType } from "bungie-api-ts/destiny2";

export class Player {
    id: string;
    platform: number;
    player1: string;
    player2: string;
    player3: string;
    character1: string;
    character2: string;
    character3: string;
    activity: string;
    activityStarted: string;
    score: string;
    lastRefresh: string;
    online: boolean;
    lastOnline: Date;

    constructor(
        id: string,
        platform: number,
        player1: string,
        player2: string,
        player3: string,
        character1: string, 
        character2: string, 
        character3: string, 
        activity: string,
        activityStarted: string,
        score: string,
        lastRefresh: string,
        online: boolean,
        lastOnline: Date,
    ) {
        this.id = id;
        this.platform = platform;
        this.player1 = player1;
        this.player2 = player2;
        this.player3 = player3;
        this.character1 = character1;
        this.character2 = character2;
        this.character3 = character3;
        this.activity = activity;
        this.activityStarted = activityStarted;
        this.score = score;
        this.lastRefresh = lastRefresh;
        this.online = online;
        this.lastOnline = lastOnline;
    }
}

export class Profile {
    key: number | null;
    platform: BungieMembershipType;
    id: string;
    name: string;
    character1: string;
    character2: string;
    character3: string;
    
    lastOnline: Date;

    constructor(key: number | null, platform: number, id: string, name: string, character1: string, character2: string, character3: string, lastOnline: Date) {
        this.key = key;
        this.id = id;
        this.platform = platform;
        this.name = name;
        this.character1 = character1;
        this.character2 = character2;
        this.character3 = character3;
        this.lastOnline = lastOnline;
    }
}