import {
    BungieMembershipType,
    DestinyComponentType,
    DestinyProfileResponse,
    getProfile,
    HttpClientConfig,
    ServerResponse,
    getDestinyManifestSlice,
    getDestinyManifest,
    PlatformErrorCodes,
    getActivityHistory,
    DestinyActivityModeType,
    getPostGameCarnageReport
} from "bungie-api-ts/destiny2";
import moment from "moment";
import { Player } from './player';
import { Match } from '../models/match';

export class Bungie {

    private activities: any;
    private activityModes: any;

    public async $http(config: HttpClientConfig) {
        // fill in the API key, handle OAuth, etc., then make an HTTP request using the config.
        try {
            const response = await fetch(
                config.url + "?" + new URLSearchParams(config.params).toString(),
                {
                    headers: {
                        "x-api-key": "5fd931d0d1634c8e9cd2a8a705fecbbc",
                    },
                }
            );
            const body = await response.json();
            return body;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public async httpClient(config: HttpClientConfig) {
        return fetch(`${config.url}?${new URLSearchParams(config.params).toString()}`, {
            method: config.method,
            body: config.body
        })
            .then((res) => res.json())
            .catch((e) => {
                console.log(console.log(e));
            });
    }

    public async getManifest(): Promise<boolean> {
        const manifestMetadata = (await getDestinyManifest(this.httpClient)).Response;
        console.log(manifestMetadata);
        const partialManifest = await getDestinyManifestSlice(this.httpClient, {
            destinyManifest: manifestMetadata,
            tableNames: [
                "DestinyActivityDefinition",
                "DestinyActivityModeDefinition",
            ],
            language: "en",
        });

        this.activities = partialManifest.DestinyActivityDefinition;
        this.activityModes = partialManifest.DestinyActivityModeDefinition;
        return (this.activities != null && this.activityModes != null);
    }

    public async getMatch(id: string) {
        try {
            const match = await getPostGameCarnageReport(this.$http, {
                activityId: id
            });
            return match.Response;
        } catch (error) {
            console.log(error);
        }
    }

    public async getMatches(player: Player): Promise<Match[]> {
        const matches: Match[] = [];
        if (player.character1 != "") matches.push(...await this.getMatchesByCharacter(player.character1, player.id, player.platform));
        if (player.character2 != "") matches.push(...await this.getMatchesByCharacter(player.character2, player.id, player.platform));
        if (player.character3 != "") matches.push(...await this.getMatchesByCharacter(player.character3, player.id, player.platform));
        matches.sort((a, b) => (a.date < b.date) ? 1 : -1);
        return matches;
    }

    private async getMatchesByCharacter(characterId: string, membershipId: string, platform: number): Promise<Match[]> {
        const matches: Match[] = [];
        const activities = await getActivityHistory(this.$http, {
            characterId: characterId,
            count: 25,
            destinyMembershipId: membershipId,
            membershipType: platform,
            mode: DestinyActivityModeType.TrialsOfOsiris,
            page: 0
        });

        if (activities != null && activities.Response.activities != null && activities.ErrorCode == PlatformErrorCodes.Success) {
            activities.Response.activities.forEach(activity => {
                matches.push(
                    new Match(
                        activity.activityDetails.instanceId,
                        moment(activity.period).toDate(),
                        this.activities[activity.activityDetails.referenceId]?.displayProperties.name ?? "",
                        this.activities[activity.activityDetails.directorActivityHash]?.displayProperties.name ?? "",
                        activity.values.activityDurationSeconds.basic.displayValue,
                        activity.values.standing.basic.value == 0,
                        activity.activityDetails.membershipType
                    )
                );
            });
        }
        return matches;
    }

    public async getData(playerId: string, platform: BungieMembershipType): Promise<Player | null> {
        const profileInfo: ServerResponse<DestinyProfileResponse> = await getProfile(
            this.$http,
            {
                components: [
                    DestinyComponentType.Profiles,
                    DestinyComponentType.CharacterActivities,
                    DestinyComponentType.Transitory,
                ],
                destinyMembershipId: playerId,
                membershipType: platform,
            }
        );

        if (profileInfo == null || profileInfo.ErrorCode != PlatformErrorCodes.Success) return null;

        let player1 =
            profileInfo.Response.profile.data?.userInfo.displayName ?? "";
        let player2 = "";
        let player3 = "";
        let score = "";
        let activity = "";
        let activityStarted = "";
        let online = false;
        let players = "";
        let lastOnline = new Date();

        if (profileInfo.Response.profileTransitoryData.data != null) {
            player1 = "";
            online = true;
            const data = profileInfo.Response.profileTransitoryData.data;
            data.partyMembers.forEach((member) => {
                if (player1 == "") {
                    player1 = member.displayName;
                } else if (player2 == "") {
                    player2 = member.displayName;
                } else if (player3 == "") {
                    player3 = member.displayName;
                }
            });

            if (data.currentActivity != null) {
                score =
                    data.currentActivity.score +
                    " - " +
                    data.currentActivity.highestOpposingFactionScore;
                players = `${data.currentActivity.numberOfPlayers} - ${data.currentActivity.numberOfOpponents}`;
                activityStarted = moment(profileInfo.Response.profileTransitoryData.data.currentActivity.startTime).fromNow();
            }
        }
        let date = 0;
        lastOnline = moment(profileInfo.Response.profile.data?.dateLastPlayed).toDate();

        for (const id in profileInfo.Response.characterActivities.data) {
            const character = profileInfo.Response.characterActivities.data[id];
            if (character.currentActivityHash != null) {
                const started = Date.parse(character.dateActivityStarted);
                if (started > date) {
                    if (character.currentActivityHash != 0) {
                        activity = `${this.activityModes[character.currentActivityModeHash]?.displayProperties.name ?? ""} ${this.activities[character.currentActivityHash]?.displayProperties.name} (${players})`;
                    }
                    date = started;
                }
            }
        }

        return new Player(
            playerId,
            platform,
            player1,
            player2,
            player3,
            profileInfo.Response.profile.data?.characterIds.length ?? 0 > 0 ? profileInfo.Response.profile.data?.characterIds[0] ?? "" : "",
            profileInfo.Response.profile.data?.characterIds.length ?? 0 > 1 ? profileInfo.Response.profile.data?.characterIds[1] ?? "" : "",
            profileInfo.Response.profile.data?.characterIds.length ?? 0 > 2 ? profileInfo.Response.profile.data?.characterIds[2] ?? "" : "",
            activity,
            activityStarted,
            score,
            moment().format("HH:mm:ss"),
            online,
            lastOnline
        );
    }
}