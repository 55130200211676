import { connection } from "./jsstore_con";
import { DATA_TYPE } from "jsstore";

const getDatabase = () => {
    const tblProfiles = {
        name: 'Profiles',
        columns: {
            key: {
                primaryKey: true,
                autoIncrement: true
            },
            id: {
                notNull: true,
                dataType: DATA_TYPE.String
            },
            platform: {
                notNull: true,
                dataType: DATA_TYPE.Number
            },
            name: {
                dataType: DATA_TYPE.String,
                default: ''
            },
            lastOnline: {
                dataType: DATA_TYPE.DateTime
            }
        }
    };
    const dataBase = {
        name: "destiny_players",
        tables: [tblProfiles]
    };
    return dataBase;
};

export const initJsStore = async () => {
    const dataBase = getDatabase();
    return await connection.initDb(dataBase);
};

