<template>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" dark v-bind="attrs" v-on="on">
          Add <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="primary">
          Add player
        </v-card-title>

        <v-form>
          <v-container>
            <v-row>
              <v-col cols="12" md="4">
                <v-select
                  v-model="select"
                  :items="platforms"
                  item-text="name"
                  item-value="id"
                  label="Select"
                  return-object
                  single-line
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field v-model="id" label="Id"></v-text-field>
              </v-col>
            </v-row>
            <v-row><v-col>-Or-</v-col></v-row>
            <v-col><v-row>
            <v-text-field v-model="url" label="Url"></v-text-field>
            </v-row></v-col>
          </v-container>
        </v-form>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="submit"> Add </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>

<script>
import { BungieMembershipType } from "bungie-api-ts/destiny2";
import Vue from 'vue';

export default Vue.extend({
  data: () => ({
    dialog: false,
    select: { name: "Playstation", id: BungieMembershipType.TigerPsn },
    platforms: [
      { name: "XBox", id: BungieMembershipType.TigerXbox },
      { name: "Playstation", id: BungieMembershipType.TigerPsn },
      { name: "Steam", id: BungieMembershipType.TigerSteam },
      { name: "Stadia", id: BungieMembershipType.TigerStadia },
    ],
    id: "",
    url: "",
  }),
  methods: {
    submit() {
      let platform = this.select.id;
      let id = this.id;
      if(this.url != "") {
        const match = this.url.match(/\/(\d)\/(\d+)/);
        if(match != null) {
          platform = +match[1];
          id = match[2];
        }
      }

      if(id != "") this.$emit("addPlayer", platform, id);
      this.id = "";
      this.url = "";
      this.dialog = false;
    },
  },
});
</script>
<style>
  .v-input__control .v-text-field__details {
    display: none;
  }
</style>