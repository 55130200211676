











































import { BungieMembershipType, DestinyPostGameCarnageReportData } from "bungie-api-ts/destiny2";
import { Bungie } from "../helpers/bungie";
import Vue from 'vue';

const bungie = new Bungie();
let match: DestinyPostGameCarnageReportData | undefined;

export default Vue.extend({
  props: {
      matchId: String,
  },
  data: () => ({
    match: match,
    open: false
  }),
  watch: {
      matchId: function(matchId: string) {
          if(matchId != "") {
              this.open = true;
              this.getMatchDetails(matchId);
          } else {
              this.open = false;
          }
      },
      open: function(isOpen: boolean) {
          if(!isOpen) {
              this.$emit('closed');
              this.match = undefined;
          }
      }
  },
  methods: {
    async getMatchDetails(matchId: string) {
        if(matchId == "") return;
        this.match = await bungie.getMatch(matchId);
    },
    addPlayer(membershipId: string, platform: BungieMembershipType) {
        this.$emit("addPlayer", platform, membershipId);
    }
  },
});
