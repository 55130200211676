



















































































































import NewPlayer from "./NewPlayer.vue";
import Players from "./Players.vue";
import MatchDetails from "./MatchDetails.vue";
import {
  BungieMembershipType,
  DestinyHistoricalStatsPeriodGroup,
} from "bungie-api-ts/destiny2";
import { Player, Profile } from "../helpers/player";
import { Match } from "../models/match";
import { Bungie } from "../helpers/bungie";
import { ProfileService } from "../service/profile_service";
import Vue from 'vue';
import moment from "moment";

const players: Player[] = [];
let profiles: Profile[] = [];
const bungie: Bungie = new Bungie();
const matches: Match[] = [];
let openPlayer: Player;
const timeouts: number[] = [];

export default Vue.extend({
  components: {
    NewPlayer,
    Players,
    MatchDetails,
  },
  data() {
    return {
      headers: [
        { text: "Player 1", value: "player1" },
        { text: "Player 2", value: "player2" },
        { text: "Player 3", value: "player3" },
        { text: "Activity", value: "activity" },
        { text: "Started", value: "activityStarted" },
        { text: "Score", value: "score" },
        { text: "Refreshed", value: "lastRefresh" },
      ],
      getdata: true,
      showoffline: false,
      players: players,
      profiles: profiles,
      componentKey: 0,
      service: new ProfileService(),
      matches: matches,
      loadingMatches: false,
      matchId: "",
      offline: false,
    };
  },
  mounted() {
    this.start();
    this.getdata = localStorage.getItem("getdata") != "false";
    this.showoffline = localStorage.getItem("showoffline") != "false";
  },
  destroyed() {
    this.clearTimeouts();
  },
  watch: {
    getdata(value) {
      if (value) this.loopProfiles();
      else this.clearTimeouts();
      localStorage.setItem("getdata", this.getdata ? "true" : "false");
    },
    showoffline(value) {
      localStorage.setItem("showoffline", this.showoffline ? "true" : "false");
    },
  },
  methods: {
    moment: moment,
    async getMatches(player: Player) {
      matches.length = 0;
      this.loadingMatches = true;
      const m = await bungie.getMatches(player);
      if (player.id == openPlayer.id) {
        this.loadingMatches = false;
        if (m != null) {
          matches.push(...m);
        }
      }
    },
    expandRow(item: any) {
      const player: Player = item.item;
      openPlayer = player;
      if (item.value) this.getMatches(player);
    },
    getMatch(activityId: string) {
      this.matchId = activityId;
    },
    matchClosed() {
      this.matchId = "";
    },
    delay(ms: number) {
      return new Promise((resolve) => timeouts.push(setTimeout(resolve, ms)));
    },
    clearTimeouts() {
      timeouts.forEach((timeout) => clearTimeout(timeout));
      timeouts.length = 0;
    },
    reload() {
      location.reload();
    },
    async addPlayer(platform: BungieMembershipType, userId: string) {
      const player = await bungie.getData(userId, platform);
      if (player != null) {
        const profile = new Profile(
          null,
          platform,
          userId,
          player.player1,
          player.character1,
          player.character2,
          player.character3,
          player.lastOnline
        );
        this.getProfile(profile);
        const index = profiles.map((x) => x.id).indexOf(player.id);
        if (index !== -1) {
          this.$set(profiles, index, profile);
        } else {
          profiles.push(profile);
        }
        await this.service.addProfile(profile);
      } else {
        alert("player not found");
      }
    },
    async start() {
      profiles = await this.service.getProfiles();
      this.offline = !await bungie.getManifest();
      if(!this.offline) this.loopProfiles();
    },
    pushPlayer(player: Player) {
      const index = players.map((x) => x.id).indexOf(player.id);
      if (index !== -1) {
        this.$set(players, index, player);
      } else {
        players.push(player);
      }
    },
    async loopProfiles() {
      for (const key in profiles) {
        this.getProfile(profiles[key]);
      }
    },
    async getProfile(profile: Profile) {
      if (!this.getdata) return;
      const player = await bungie.getData(profile.id, profile.platform);
      if (profile.key != null && player != null) {
        this.service.updateLastOnline(profile.key, player.lastOnline);
      }
      if (player != null) {
        this.pushPlayer(player);
        if (player.online) {
          await this.delay(10000);
        } else {
          await this.delay(60000);
        }
      } else {
        await this.delay(300000);
      }
      this.getProfile(profile);
    },
  },
});

//TODO: move match history to seperate component
// add player by pasting d2checklist or trials.report link
// 
